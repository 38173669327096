//
// user-variables.scss
// Use this to overwrite Bootstrap and Geeks variables
//
// Example of a variable override to change Geeks primary color
// Remove the "//" to comment it in and see it in action!
// $primary: #000;

$navbar-caret-color: #60B132;
$navbar-light-color: #fff;
$navbar-light-hover-color: #97989A;
$navbar-light-active-color: #97989A;
$navbar-dark-color: #000;
$navbar-dark-hover-color: #97989A;
$navbar-dark-active-color: #97989A;
$burger-icon-line-color: #fff;

$primary: #60B132;
$secondary: #BC2D55;
$muted: #97989A;

$carousel-indicator-active-bg: #97989A;

