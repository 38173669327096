.bg-experiencia {
  background-image: url("assets/images/falabella/bg-vive-experiencia.png");
  background-repeat: no-repeat;
  background-position: 35%;
  background-size: cover;
}

.border-card-experiencia {
  border-radius: 2.5rem;
  min-height: 380px !important;
}

.border-card-experiencia-item {
  border-radius: 2.5rem;
}

.arrowDerechaViveExperiencia{
  background-color: #ffff !important;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ecebf1;
  transition: .2s ease-in;
  color: #beb9cd;
  margin-left: -2%;
  margin-right: -2%;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: black;
  outline: none;
  background: transparent;
  right: -25px;
}

.arrowDerechaViveExperiencia:hover{
  background-color: rgb(146, 190, 73) !important;
}

.slick-next-ViveExperiencia:before {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  transition: .2s ease-in;
  color: #beb9cd;
  margin-left: -2%;
  margin-right: -2%;
}

.slick-next-ViveExperiencia:before {
  font-family: 'Feather' !important;
  content: "\2192";
  color: #beb9cd;
  font-size: 22px;
}

.slick-next-ViveExperiencia:hover::before {
  color: #fff !important;
}


.arrowIzqViveExperiencia {
  background-color: #fff !important;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ecebf1;
  transition: .2s ease-in;
  color: #beb9cd;
  margin-left: -2%;
  margin-right: -2%;
  font-size: 0;
  position: absolute;
  padding: 0;
  transform: translate(0, -50%);
  outline: none;
  background: transparent;
  left: -25px;
  top: 50%;
  cursor: pointer;
}

.arrowIzqViveExperiencia:hover{
  background-color: rgb(146, 190, 73) !important;
}

.slick-prev-ViveExperiencia:before {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  transition: .2s ease-in;
  color: #beb9cd;
  margin-left: -2%;
  margin-right: -2%;
}

.slick-prev-ViveExperiencia:before {
  font-family: 'Feather' !important;
  content: "\2190";
  color: #beb9cd;
  font-size: 22px;
}

.slick-prev-ViveExperiencia:hover::before {
  color: #fff !important;
}