.bg-experiencia-cargos-jovenes {
    background-image: url("assets/images/falabella/jovenes/Mesa-de-trabajo-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 110vh;
    background-color: #E1E7FF;
  }
  
  .icon-cargos {
    margin: 0 auto;
    display: block;
  }

  .color-bar-cargos {
    display: flex;
    height: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .color-block {
    flex-grow: 1;
    height: 100%;
  }
  
  .blue-falabella{
    background-color: #2B49C0;
  }
  .green-falabella{
    background-color: #86C12E;
  }
  .red-falabella{
    background-color: #CD0B51;
  }
  .gray-falabella{
    background-color: #98999B;
  } 
  