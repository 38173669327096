.border-card{
    border-radius: 2.5rem;
    min-height: 600px !important;
}

.text-size {
  font-size: 15px;
}

.card {
    border: 0rem;
    box-shadow: 0px 0px 0px rgb(3 0 71 / 9%);
}

.card-footer {
    padding: 1rem 1.5rem;
    background-color: transparent; 
    border-top: 0px solid #ecebf1;
}

.text-justify {
  text-align: justify;
}

.avatar-xxl {
    width: 12.5rem;
    height: 12.5rem;
    z-index: 2;
    margin-bottom: -25%;
}

.ubicacion{
    z-index: 2;
}

.border-secondary-eventos{
    border-color: #111 !important;
} 
.title-section h2 {
    font-size: 24px; 
    line-height: 1.2;
  }
  
  .title-bold {
    font-weight: bold;
  }
  
  .title-red {
    color: red;
  }
  
  .title-normal {
    font-weight: normal;
    color: black; 
  }