.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader img {
  width: 200px; /* Ajusta el tamaño de la imagen según tus necesidades */
  animation: gradientFade 2s ease-in-out infinite;
}

@keyframes gradientFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
