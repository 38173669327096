.bg-experiencia-cargos-despegar {
    background-color: #86C12E;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 110vh;
  }
  
  .borderBlanco{
    border: 4px solid #FFFFFF;
  }
  
  .arrowIzqProta {
    background-color: gray !important;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    border: 1px solid #ffff;
    transition: .2s ease-in;
    color: #ffff;
    margin-left: -2%;
    margin-right: -2%;
    font-size: 0;
    position: absolute;
    padding: 0;
    transform: translate(0, -50%);
    outline: none;
    background: transparent;
    left: 47%;
    top: 100%;
    cursor: pointer;
  }
  
  .arrowDerechaProta{
    background-color: gray !important;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    border: 1px solid #ffff;
    transition: .2s ease-in;
    color: #ffff;
    margin-left: -2%;
    margin-right: -2%;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 100%;
    display: block;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: black;
    outline: none;
    background: transparent;
    right: 47%;
  }
  
  #mi-modal .profile-card {
    max-width: 500px;
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    overflow: hidden;
  }
  
  #mi-modal .profile-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  #mi-modal .profile-card .card-body {
    padding: 10px;
    background-color: black;
  }
  
  #mi-modal .card-footer {
    background-color: black;
  }
  
  #mi-modal .profile-card .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  #mi-modal .profile-card .card-text {
    font-size: 18px;
    line-height: 1.5;
    color: #666;
  }
  
  #mi-modal .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
    left: 0%;
  }
  
  @media (max-width: 996px) {
    #mi-modal .modal-content {
      background-color: transparent !important;
      box-shadow: none !important;
      border: none !important;
      left: 0%;
    }
  }
  
  
#mi-modal .modal-dialog {
margin: 0;
}

.avatar-xxl2 {
    width: 25rem !important;
    height: 25rem !important;
    z-index: 2;
    margin-bottom: -25%;
}

.avatar-l{
    width: 8rem !important;
    height: 8rem !important;
    z-index: 2;
    margin-bottom: -25%;
}

.arrowDerechaViveExperienciaJovenes{
    background-color: #CD0B51 !important;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    border: 1px solid #ffff;
    transition: .2s ease-in;
    color: #ffff;
    margin-left: -2%;
    margin-right: -2%;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: black;
    outline: none;
    background: transparent;
    right: -25px;
  }
  
  .arrowDerechaViveExperienciaJovenes:hover{
    background-color: rgb(146, 190, 73) !important;
  }
  
  .slick-next-ViveExperienciaJovenes:before {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 35px;
    text-align: center;
    transition: .2s ease-in;
    color: #ffff;
    margin-left: -2%;
    margin-right: -2%;
  }
  
  .slick-next-ViveExperienciaJovenes:before {
    font-family: 'Feather' !important;
    content: "\2192";
    color: #ffff;
    font-size: 22px;
  }
  
  .slick-next-ViveExperienciaJovenes:hover::before {
    color: #fff !important;
  }
  
  
  .arrowIzqViveExperienciaJovenes {
    background-color: #CD0B51 !important;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    border: 1px solid #ffff;
    transition: .2s ease-in;
    color: #ffff;
    margin-left: -2%;
    margin-right: -2%;
    font-size: 0;
    position: absolute;
    padding: 0;
    transform: translate(0, -50%);
    outline: none;
    background: transparent;
    left: -25px;
    top: 50%;
    cursor: pointer;
  }
  
  .arrowIzqViveExperienciaJovenes:hover{
    background-color: rgb(146, 190, 73) !important;
  }
  
  .slick-prev-ViveExperienciaJovenes:before {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 35px;
    text-align: center;
    transition: .2s ease-in;
    color: #ffff;
    margin-left: -2%;
    margin-right: -2%;
  }
  
  .slick-prev-ViveExperienciaJovenes:before {
    font-family: 'Feather' !important;
    content: "\2190";
    color: #ffff;
    font-size: 22px;
  }
  
  .slick-prev-ViveExperienciaJovenes:hover::before {
    color: #fff !important;
  }

  .color-bar {
    height: 10px;
    display: flex;
  }
  
  .color-block {
    flex-grow: 1;
    height: 100%;
  }
  
  .blue-falabella{
    background-color: #2B49C0;
  }
  .green-falabella{
    background-color: #86C12E;
  }
  .red-falabella{
    background-color: #CD0B51;
  }
  .gray-falabella{
    background-color: #98999B;
  }  