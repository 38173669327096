.bg-disena {
    background-image: url("assets/images/falabella/falabellatech/disena/Falabella-Tech-atraccion-de-talento-banner-6.jpg");
    background-repeat: no-repeat;
    min-height: 110vh;
    background-size: cover;
}
.bg-disena-mobile {
    background-image: url("assets/images/falabella/falabellatech/protagonista/Falabella-Tech-atraccion-de-talento-banner-7.jpg");
    background-repeat: no-repeat;
    min-height: 110vh;
    background-size: cover;
}

.border-card-disena {
    border-radius: 2.5rem;
    min-height: 300px !important;
    background-color: black;
  }