.bg-primeros-pasos {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 110vh;
}
.card-container {
  position: relative;
  height: 400px; /* Ajusta la altura según tus necesidades */
}

.overlay-card {
  position: absolute;
  top: 102%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  background-color: rgb(255 255 255);
  border-radius: 20px;
  padding: 10px;
}

.background-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px; /* Ajusta el radio de los bordes según tus necesidades */
  overflow: hidden;
}

.vertical-image {
  max-width: 100%;
  height: 100%;
  width: auto;
  object-fit: cover;
  object-position: center top; /* Ajusta la posición de la imagen según tus necesidades */
}




.card-container-center {
  position: relative;
  height: 525px; /* Ajusta la altura según tus necesidades */
}

.overlay-card-center {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  background-color: rgb(255 255 255);
  border-radius: 20px;
  padding: 10px;
}

.background-card-center {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px; /* Ajusta el radio de los bordes según tus necesidades */
  overflow: hidden;
  bottom:11%
}

.vertical-image-center {
  max-width: 100%;
  height: 100%;
  width: auto;
  object-fit: cover;
  object-position: center top; /* Ajusta la posición de la imagen según tus necesidades */
}


.btn-custom-paso1 {
  color: #ffffff;
  background-color: #2E5EB7;
  border-color: #2E5EB7;
}

.btn-custom-paso1:disabled,
.btn-custom-paso1:disabled:hover {
  color: #ffffff;
  background-color: #2E5EB7;
  border-color: #2E5EB7;
  cursor: default; /* El cursor no cambia a la mano al pasar por encima */
}

.btn-custom-paso2 {
  color: #ffffff;
  background-color: #86C12E;
  border-color: #86C12E;
}

.btn-custom-paso3 {
  color: #ffffff;
  background-color: #CD0B51;
  border-color: #CD0B51;
}

@media (max-width: 995px) {
  .mobile-spacing {
    margin-top: 20px !important;
  }
}

.mb-especial-paso1{
  margin-bottom: 15rem;
}

.mb-especial-paso2 {
  margin-bottom: 7rem;
}

.btn-gray{
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.rounded-icon{
  border-radius: 8px;
}