.testimonio {
  z-index: 2;
  position: absolute;
  top: 22%;
  left: 38%;
}

.imgMapCarousel {
  width: 300px !important;
  height: auto !important;
  text-align: center;
  cursor: pointer;
}

.imgMapCarouselMobile {
  width: 150px !important;
  height: auto !important;
  text-align: center;
}

.imgDef {
  width: 150px !important;
  height: auto !important;
  text-align: center;
}

.text-mapping {
  color: #3e3e3d;
  font-size: 12px;
  border-left: 2px solid rgb(62, 84, 161);
  padding-left: 2px;
  margin-right: 2px;
}

.tooltiptext {
  width: 190px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px 15px;
  /* Position the tooltip */
  cursor: pointer;

  z-index: 1;
}

.styleTestimonio {
  background: rgba(255, 255, 255, 0.8) !important;
}

area {
  cursor: pointer;
}

.bg-carousel-mapas-mobile {
  background-image: url("assets/images/falabella/mapMobile.png");
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 200px;
  position: relative;
}

.bg-carousel-mapas-interno {
  background-image: url("assets/images/falabella/mapMobile.png");
  background-repeat: no-repeat;
  background-position: 35%;
  background-size: 100%;
  height: 800px;
}

.marginMobileCaraouselLeft{
  margin-left: 45%;
  cursor: pointer;
}

.centerBotonMobile{
  margin:auto;
}

.carouselMargin{
  margin-top: 14% !important;
}

.carouselMarginInterno{
  margin-top: 15% !important;
}

.marginMobileCaraouselLeftInterno{
  margin-left: 35%;
  cursor: pointer;
}

.bg-transparente{
  background: transparent;
}

@media (min-width:641px)  
{ 
  .slick-next
  {
    margin-right: 3% !important;
  }
  .slick-prev {
    margin-left: 2% !important;
    z-index: 6;
  } 
}
@media (min-width:961px)  
{ 
  .slick-next
  {
    margin-right: 3% !important;
  }
  .slick-prev {
    margin-left: 2% !important;
    z-index: 6;
  } 
}
@media (min-width:1025px) 
{ 
  .slick-next
  {
    margin-right: 3% !important;
  }
  .slick-prev {
    margin-left: 2% !important;
    z-index: 6;
  } 
}
@media (min-width:1281px) 
{ 
  .slick-next
  {
    margin-right: 3% !important;
  }
  .slick-prev {
    margin-left: 2% !important;
    z-index: 6;
  } 
}