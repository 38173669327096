.bg-experiencia-cargos {
  background-image: url("assets/images/falabella/trama-fondo-blanco.png");
  background-repeat: no-repeat;
}

.arrowDerechaViveExperienciaCargo{
  background-color: gray !important;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ffff;
  transition: .2s ease-in;
  color: #ffff;
  margin-left: -2%;
  margin-right: -2%;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: black;
  outline: none;
  background: transparent;
  right: -25px;
}

.arrowDerechaViveExperienciaCargo:hover{
  background-color: rgb(146, 190, 73) !important;
}

.slick-next-ViveExperienciaCargo:before {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  transition: .2s ease-in;
  color: #ffff;
  margin-left: -2%;
  margin-right: -2%;
}

.slick-next-ViveExperienciaCargo:before {
  font-family: 'Feather' !important;
  content: "\2192";
  color: #ffff;
  font-size: 22px;
}

.slick-next-ViveExperienciaCargo:hover::before {
  color: #fff !important;
}


.arrowIzqViveExperienciaCargo {
  background-color: gray !important;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ffff;
  transition: .2s ease-in;
  color: #ffff;
  margin-left: -2%;
  margin-right: -2%;
  font-size: 0;
  position: absolute;
  padding: 0;
  transform: translate(0, -50%);
  outline: none;
  background: transparent;
  left: -25px;
  top: 50%;
  cursor: pointer;
}

.arrowIzqViveExperienciaCargo:hover{
  background-color: rgb(146, 190, 73) !important;
}

.slick-prev-ViveExperienciaCargo:before {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  transition: .2s ease-in;
  color: #ffff;
  margin-left: -2%;
  margin-right: -2%;
}

.slick-prev-ViveExperienciaCargo:before {
  font-family: 'Feather' !important;
  content: "\2190";
  color: #ffff;
  font-size: 22px;
}

.slick-prev-ViveExperienciaCargo:hover::before {
  color: #fff !important;
}