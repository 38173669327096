  #carouseljovenes {
    background-color: #e0e0e0; /* Define el color de fondo gris deseado */
  }
  
  .h1-prescencia {
      font-family: OpenSans-ExtraBold !important;
      color: #fff !important;
  }
  .p-prescencia {
    font-size: 1.5rem;
    margin: 0 1.25rem 5rem 1.25rem;
  }
  
  .float-right-prescencia{
    float: right;
    margin-right: 200px;
  }
  
  .text-carousel {
    position: absolute;
    top: 50%;
    left: 32%;
    transform: translate(-50%, -50%);
    text-align: left;
  
  }
  
  .text-carousel-mobile {
    position: absolute;
    top: 25%;
    left: 40%;
    transform: translate(-50%, -50%);
    text-align: left;
  }
  
  .carouselInternoTexto{
    padding-left: 15%;
    margin-top: 14%;
    margin-left: -10%;
  
  }
  .textInterno{
    font-weight: bolder;
    font-size:65px;
    font-family: OpenSans-ExtraBold !important;
    
  }
  .textInternoSmall{
    font-weight: bolder;
    font-size:35px;
    font-family: OpenSans-ExtraBold !important;
    
  }
  
  .carouselInternoTextomobile{
    padding-left: 15%;
    margin-top: 110%;
  
  }
  
  .carouselInternoTextomobileMobile{
    padding-left: 15%;
    margin-top: 120%;
    
  }
  
  
  .textInternoColorTottus{
    font-weight: bolder;
    color:#507622;
    font-family: OpenSans-ExtraBold !important;
  }
  
  .textInternoColorSodimac{
    font-weight: bolder;
    color:#F9D202;
    font-family: OpenSans-ExtraBold !important;
  }
  .textInternoColorFalabellacom{
    font-weight: bolder;
    color:#3E3E3D;
    font-family: OpenSans-ExtraBold !important;
  }
  
  .textInternoColorMallplaza{
    font-weight: bolder;
    color:#ffffff;
    font-family: OpenSans-ExtraBold !important;
  }
  
  .textInternoColorBancoFalabella{
    font-weight: bolder;
    color:#CFD400;
    font-family: OpenSans-ExtraBold !important;
  }
  
  .textInternoColorFalabellaInmobiliario{
    font-weight: bolder;
    color:#ffffff;
    font-family: OpenSans-ExtraBold !important;
  }
  .textInternoColorFalabellaFinanciero{
    font-weight: bolder;
    color:#3E3E3D;
    font-family: OpenSans-ExtraBold !important;
  }
  .textInternoColorFalabellaSoriana{
    font-weight: bolder;
    color:#3E3E3D;
    font-family: OpenSans-ExtraBold  !important;
  }
  .textInternoColorSegurosFalabella{
    font-weight: bolder;
    color:#3E3E3D;
    font-family: OpenSans-ExtraBold !important;
  }
  
  .carousel-text-jovenes {
    position: absolute;
    top: 65%;
    left: 32%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: left;
    padding-left: 20px;
  
  }
  
  .carousel-text-mobile-jovenes {
    position: absolute;
    top: 35%;
    left: 35%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: left;
    padding-left: 20px;
  
  }
  
  .carousel-text-english-jovenes {
    position: absolute;
    top: 65%;
    left: 30%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: left;
    padding-left: 20px;
  
  }
  
  .carousel-tag-top {
    position: absolute;
    top: 55%;
    left: 20%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: left;
  }
  .carousel-tag-top h1{
    color:yellow;
    padding-bottom: 30px;
  }
  
  .carousel-tag-bottom {
    position: absolute;
    top: 75%;
    left: 20%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: left;
  }
  
  .carousel-tag-bottom h1{
    color:yellow;
    padding-top: 30px;
  }
  
  .carousel-text h1{
    color:white
  }
  
  .carousel-tag-button{
    position: absolute;
    top: 40%;
    left: 25%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: left;
    display: inline-block;
    color: black;
    border: 1px solid white;
    padding: 0px 20px;
    border-radius: 50px;
  }
  
  .carousel-tag-button-mobile{
    position: absolute;
    top: 20%;
    left: 40%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: left;
    display: inline-block;
    color: black;
    border: 1px solid white;
    border-radius: 50px;
  }
  
  .image-wrapper-carousel-jovenes {
    position: relative;
    display: inline-block;
  }
  
  .image-wrapper-carousel-jovenes:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  
  
  }
  
  .image-wrapper-carousel-jovenes img {
    display: block;
    max-width: 100%;
    height: auto;
 
  }