@mixin navbar-title-caret {
  margin-top: -38px;
  padding-bottom: 20px;
  &:before,
  &:after {
    border-color: #97989a transparent transparent transparent;
    border-style: solid;
    border-width: 10px;
    content: "";
    left: 10px;
    margin: 0 0 0 0px;
    position: relative;
    top: 45px;
  }

  &:after {
    border-color: #3e3e3d transparent transparent transparent;
    top: 44px;
    left: -10px;
  }
}

.navbar-title-caret {
  @include navbar-title-caret;
}

.scroll-down-floating {
  cursor: pointer;
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 40px;
  right: 47%;
  background-color: transparent;
  z-index: 100;

  @media (max-width: 991.98px) {
    bottom: 120px;
    right: 40%;
  }
}

.wrapper {
  display: flex;
  position: relative;
  z-index: 1;
  counter-reset: item-scroll;
  height: 100vh;

  .item-scroll {
    position: relative;
    padding: 50px 10px;
    //flex: 0 0 1000px;
    height: 100vh;
    //display: flex;
    align-items: center;
    line-height: 1.7;
    user-select: none;

    &:nth-child(2n + 2) {
      align-items: flex-start;
    }

    &:nth-child(4n + 4) {
      align-items: flex-end;
    }
  }
}

.menu-principal {
  padding-right: 4rem;
  border-radius: 2.5rem;
  .dropdown-menu {
    background-color: #3e3e3d;
    opacity: 0.9 !important;
    .dropdown-item {
      color: #97989a;
      border-bottom: 1px solid #97989a;
    }
    :last-child {
      border-bottom: 0;
    }
  }
}
.menu-principal-lang-dark {
  .nav-link{
    color:#97989A;
  }
  .dropdown-menu {
    background-color: #3e3e3d;
    opacity: 0.9 !important;
    .dropdown-item {
      color: #97989a;
      border-bottom: 1px solid #97989a;
    }
    :last-child {
      border-bottom: 0;
    }
  }
}

.menu-principal-lang-light {
  .nav-link{
    color:#97989A;
  }
  .dropdown-menu {
    background-color: #3e3e3d;
    opacity: 0.9 !important;
    .dropdown-item {
      color: #97989a;
      border-bottom: 1px solid #97989a;
    }
    :last-child {
      border-bottom: 0;
    }
  }
}

.menu-principal-lang-light-interno {
  .nav-link{
    color:#111111;
  }
  .dropdown-menu {
    background-color: #3e3e3d;
    opacity: 0.9 !important;
    .dropdown-item {
      color: #97989a;
      border-bottom: 1px solid #97989a;
    }
    :last-child {
      border-bottom: 0;
    }
  }
}

.ecosistema {
  border: 2px solid #3e3e3d;
  border-left: none;
  border-radius: 2.5rem;
  height: 33px;
  width: 300px;
  cursor: pointer;
  transition: 0.3s;
  font-family: OpenSans-ExtraBold !important;
  span {
    padding-right: 15px;
    margin-left: -21px;
    font-size: 3rem;
    margin-top: -10px;
    vertical-align: -webkit-baseline-middle;
  }
  &:hover {
    background-color: rgb(62, 84, 161);
    color: #fff;
    transform: scale(1.1);
  }
}
#cultura {
  position: relative;
}
.video-cultura > div {
  width: 100% !important;
  height: auto !important;
  min-height: 360px;
  overflow: hidden;
}

.video-cultura > div > video {
  @media screen and (max-width: 699.98px) {
    width: auto !important;
    height: auto !important;
    margin-left: -240px;
  }
}

.mensaje {
  z-index: 10;
  position: absolute;
  top: 40%;
  @media screen and (max-width: 991.98px) {
    top: 30%;
  }
}
.mensajetitle {
  z-index: 10;
  position: absolute;
  top: 40%;
  left: 0;
  bottom: 0;
  right: 0;
  @media screen and (max-width: 991.98px) {
    top: 30%;
  }
}

.img-mapper-container {
  position: relative;
  overflow: hidden;
  float: left;
  width: 100%;
  @media screen and (max-width: 699.98px) {
    left: -10% !important;
  }
}

.subTitle {
  font-family: OpenSans-ExtraBold !important;
  font-size: 2rem !important;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 1.5rem !important;
    padding: 0 5px 0 5px;
  }
}

.bg-muevete-pre {
  padding-top: 160px;
}

.bg-muevete {
  overflow: hidden;
  background-color: #32312f;
  background-repeat: repeat;
  background-position: 35%;
  padding-top: 100px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    padding-top: 50px;
  }
}

.classH3Size {
  font-family: OpenSans-ExtraBold !important;
  font-size: 2.5rem !important;
}
.classH3SizeTech {
  font-family: OpenSans-ExtraBold !important;
  font-size: 2.5vw !important;
}

.classH4Size {
  font-family: OpenSans-ExtraBold !important;
  font-size: 2rem !important;
}
