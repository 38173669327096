.titleGreenOferta{
    Color:#60b132;
}
.offerWhatapp{
    Color:#00bb2d;
    font-weight: bold !important;
    font-size: 20px;
}
.offerLinkedin{
    Color:#007dbb;
    font-weight: bold;
    font-size: 20px;
    margin-left: 10px;
}
.offerMail{
    color:black;
    font-weight: bold;
    font-size: 20px;
    margin-left: 10px;
}

.brandOffer{
    color: #999;
}