.bg-experiencia-cargos-prota {
  background-image: url("assets/images/falabella/falabellatech/protagonista/Falabella-Tech-atraccion-de-talento-banner-7.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 110vh;
}

.borderAzul{
  border: 4px solid #00D2FF;
}
.borderLila{
  border: 4px solid #F650DC;
}

.arrowIzqProta {
  background-color: gray !important;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ffff;
  transition: .2s ease-in;
  color: #ffff;
  margin-left: -2%;
  margin-right: -2%;
  font-size: 0;
  position: absolute;
  padding: 0;
  transform: translate(0, -50%);
  outline: none;
  background: transparent;
  left: 47%;
  top: 100%;
  cursor: pointer;
}

.arrowDerechaProta{
  background-color: gray !important;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ffff;
  transition: .2s ease-in;
  color: #ffff;
  margin-left: -2%;
  margin-right: -2%;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 100%;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: black;
  outline: none;
  background: transparent;
  right: 47%;
}

#mi-modal .profile-card {
  max-width: 500px;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 10px;
  overflow: hidden;
}

#mi-modal .profile-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

#mi-modal .profile-card .card-body {
  padding: 10px;
  background-color: black;
}

#mi-modal .card-footer {
  background-color: black;
}

#mi-modal .profile-card .card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

#mi-modal .profile-card .card-text {
  font-size: 18px;
  line-height: 1.5;
  color: #666;
}

#mi-modal .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: none;
  left: 0%;
}

@media (max-width: 996px) {
  #mi-modal .modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    left: 0%;
  }
}


#mi-modal .modal-dialog {
  margin: 0;
}