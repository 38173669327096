.ubicacion-texto {
  z-index: 2;
  position: relative;
  top: 10%;
  left: 80%;
}

.ubicacion-texto-cultura {
  z-index: 2;
  position: relative;
  left: 80%;
}

.imgMapCarouselNuestroPropositoMobile {
  width: 280px !important;
  height: auto !important;
  text-align: center;
}

.imgMapCarouselNuestroPropositoPrincipalMobile {
  width: 320px !important;
  height: auto !important;
  text-align: center;
}

.beneficiosSpan {
  list-style: none;
}

.beneficiosSpan li::before {
  content: "\2022";
  color: rgb(146, 190, 73);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.culturaSpan {
  list-style: none;
}

.culturaSpan li::before {
  content: "\2022";
  color: rgb(62, 84, 161);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.flexibilidadSpan {
  list-style: none;
}

.flexibilidadSpan li::before {
  content: "\2022";
  color: rgb(188, 45, 85);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.talentoSpan {
  list-style: none;
}

.talentoSpan li::before {
  content: "\2022";
  color: rgb(175, 171, 171);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.talentoSpan li {
  font-size: 18px !important;
  font-family: OpenSans-Regular !important;
}

.flexibilidadSpan li {
  font-size: 18px !important;
  font-family: OpenSans-Regular !important;
}

.beneficiosSpan li {
  font-size: 18px !important;
  font-family: OpenSans-Regular !important;
}

.culturaSpan li {
  font-size: 18px !important;
  font-family: OpenSans-Regular !important;
}

.bg-proposito {
  background-image: url("assets/images/falabella/trama-fondo-blanco.png");
  background-repeat: no-repeat;
  background-position: 35%;
}

/* html,
body, */
.section-scroll.horizontal {
  overflow: hidden;
}
/* 
body {
  font-family: "Signika Negative", sans-serif;
  font-weight: 300;

  margin: 0;
  padding: 0;
}
*/
div#proposito {
  width: auto;
  height: 100vh;
  overflow: auto;
  margin: 0;
}

.section-scroll {
  width: 100%;
  height: 100%;
  position: relative;
}

.section-scroll.horizontal {
  overflow-x: hidden;
}

.section-scroll.horizontal .pin-wrap,
.section-scroll.horizontal .animation-wrap {
  display: flex;
  position: relative;
  z-index: 50;
  /*height: 100vh;*/
}

.section-scroll.horizontal .item-scroll {
  padding: 0 2rem 0 2rem;
  position: relative;
  flex: 0 0 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
}

.section-scroll.horizontal .item-scroll-last {
  padding: 0 2rem 0 2rem;
  position: relative;
  flex: 0 0 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
}

.section-scroll.horizontal .item-scroll-padding {
  position: relative;
  flex: 0 0 20%;
  height: 100vh; /*calc(100vh - 300px);*/
  display: flex;
  align-items: center;
  line-height: 1.7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
}

.item-scroll-last > div > .row,
.item-scroll > div > .row {
  max-width: 60%;
}

.ubicacionIcono{
  position: absolute;
  margin-top: -30%;
  font-size: 35px;
  color: white;
}