.sizeSabiasque{
    font-family: OpenSans-ExtraBold !important;
    font-size: 1.5rem !important;
}
.bg-dark-sabiasque{
    background-color: #111725;
}



#mi-modal-sabias-jovenes .profile-card {
    max-width: 500px;
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    overflow: hidden;
  }
  
  #mi-modal-sabias-jovenes .profile-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  #mi-modal-sabias-jovenes .profile-card .card-body {
    padding: 10px;
   
  }
  
  #mi-modal-sabias-jovenes .profile-card .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  #mi-modal-sabias-jovenes .profile-card .card-text {
    font-size: 18px;
    line-height: 1.5;
    color: #666;
  }

  #mi-modal-sabias-jovenes .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
    left: 0%;
  }

  @media (max-width: 996px) {
    #mi-modal-sabias-jovenes .modal-content {
      background-color: transparent !important;
      box-shadow: none !important;
      border: none !important;
      left: 0%;
    }
  }
 
  
  #mi-modal-sabias-jovenes .modal-dialog {
    margin: 0;
  }