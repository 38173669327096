.footer {
  position: relative;
  background: #5c5c5b;
  text-align: center;
  overflow: hidden;
  background-image: url("assets/images/falabella/bg-vive-experiencia.png");
  background-repeat: no-repeat;
  background-position: 35%;
  background-size: cover;
}

.logout-button {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #3E3E3D; 
  font-size: 12px; 
  transition: background-color 0.3s; 
}
.text-color-footer {
  color: #fff !important;
}

.line-left {
  color: #fff;
  font-size: 12px;
  border-left: 2px solid #fff;
  padding-left: 2px;
}

.line-left-first {
  color: #fff;
  font-size: 12px;
  margin-left: 70px;
  padding-left: 2px;
}

.line-left-end {
  color: #fff;
  font-size: 12px;
  margin-left: 70px;
  padding-left: 2px;
}

.size-icon {
  font-size: 40px;
}

.btn-muevete:hover {
  color: #fff;
  @include gradient-bg(#bc2d55);
  border-color: #bc2d55;
  background-color: #bc2d55;
}

.btn-ofertas {
  color: #fff;
  @include gradient-bg(#3e54a1);
  border-color: #fff;
  background-color: #3e54a1;
}

.btn-ofertas:hover {
  color: #fff;

  border-color: #fff;
  background-color: rgb(146, 190, 73);;
}

.btn-internal:hover {
  color: #fff;
  border-color: #fff;
  background-color: #F52E4B;
}
.btn-external:hover {
  color: #fff;

  border-color: #fff;
  background-color: #14df43;
}

.btn-tech {
  color: #fff;
  @include gradient-bg(#3e54a1);
  border-color: #fff;
  background-color: #3e54a1;
  border:0px;
}

.btn-jovenes {
  color: #fff;
  @include gradient-bg(#3e54a1);
  border-color: #fff;
  background-color: #ffffff;
  border:0px;
}

.btn-tech-cargo {
  color: #fff;
  @include gradient-bg(#111111);
  border-color: #fff;
  background-color: #111111;
  border:1px solid;
}

.btn-tech-cargo:hover {
  color: #fff;
  @include gradient-bg(#bc2d55);
  border-color: #bc2d55;
  background-color: #bc2d55;
}
.btn-tech:hover {
  color: #fff;
  @include gradient-bg(#bc2d55);
  border-color: #bc2d55;
  background-color: #bc2d55;
}

.btn-postular {
  width: 200px;
}
.btn-postular:hover {
  color: #fff;
  @include gradient-bg(#3e54a1);
  border-color: #3e54a1;
  background-color: #3e54a1;
}

.btn-postularcol:hover {
  color: #fff;
  @include gradient-bg(#3ea162);
  border-color: #3ea162;
  background-color: #3ea162;
}

.border-card-experiencia-cargos {
  border-radius: 2.5rem;
  border: 1px solid black;
  min-height: 450px !important;
}

.border-card-experiencia-cargos-tech {
  border-radius: 2.5rem;
  border: 1px solid white;
  min-height: 480px !important;
}

.border-card-experiencia-cargos {
  border-radius: 2.5rem;
  border: 1px solid #e0e0e0;
  min-height: 450px !important;
}

.border-card-experiencia-cargos-jovenes {
  border-radius: 2.5rem;
  border: 1px solid white;
  min-height: 420px !important;
}

.border-card-experiencia-cargos-protagonista {
  border-radius: 2.5rem;
  height: 625px !important;
  background-color: #111111;
}

.border-card-experiencia-cargos-despegar {
  border-radius: 2.5rem;
  height: 625px !important;
  background-color: rgba(255, 255, 255, 0.5);;
}

.border-card-sabiasque {
  border-radius: 2.5rem;
  min-height: 600px !important;
  border: 2px white solid;

  background-color: black;
}

.border-card-sabiasque-jovenes {
  border-radius: 2.5rem;
  min-height: 600px !important;
  border: 2px white solid;
}

.bg-gray {
  background: #5c5c5b;
  overflow: hidden;
}

.card-jobs {
  border-radius: 2.5rem;
  border: 1px solid #97989a40;
  /* min-height: 330px !important; */
}

.card-jobs .header {
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  border: 0px solid #fff;
}

.navbar-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  border-bottom: 1px solid #97989a;
}
.navbar-item {
  border-bottom: 1px solid #97989a;
}
.navbar-item-link {
  color: #97989a;
  font-size: 1rem;
}

.activeGris {
  color: #3E3E3D !important;
}

.navbar-item-link.active {
  color: #fff;
  font-size: 1.2rem;
}

h3 {
  font-family: OpenSans-ExtraBold !important;
  font-size: 3rem !important;
}

h4 {
  font-family: OpenSans-ExtraBold !important;
  font-size: 1.5rem !important;
}

.subText {
  font-size: 24px !important;
  color: #666666;
  font-family: OpenSans-Regular !important;
}

.carousel-indicators > button {
  border: 1px solid #97989a !important;
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
  margin: 0 2px;
  opacity: 0.7;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar-button {
  width: 1px;
  height: 1px;
}

body {
  background: #111;
}

div {
  box-sizing: border-box;
}

.pointer {
  cursor: pointer;
}

.zoom-video-text:hover{
  transform: scale(1.1);
}

.navbar-title-caret {
  display: inline-block;
  transition: transform 0.2s;
}

.navbar-title-caret.open {
  transform: rotate(180deg);
}

.navbar-item {
  display: none;
  padding: 5px;
}

.border-card-sabiasque-modal {
  border-radius: 0.5rem;
  min-height: 600px !important;
  border: 2px white solid;

  background-color: black;
}

.border-card-sabiasque-modal-jovenes {
  border-radius: 0.5rem;
  min-height: 600px !important;
  border: 2px white solid;
}

.navbar-item.open {
  display: block;
}

.navCursorPointer {
  cursor: pointer;
}

.selecteditem{
  border-bottom: 3px solid #ffffff ;
}