nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

button {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

icon-light{
  color: red !important;
}

.menu {
  z-index: 999999;
  position: fixed;
  top: 0;
  right: -100%;
  width: 415px;
  height: 100%;
  background-color: #3E3E3D;
  transition: right 0.3s ease-in-out;
  overflow-y: scroll;
}

.menu-open {
  right: 0;
}
