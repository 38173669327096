.bg-mapa {
  background-image: url("assets/images/falabella/mapa-falabella.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 20%;
}

.text-color-tu-nos-importas {
  color: rgb(62, 84, 161) !important;
}

.floating-text {
  height: 100px;
  width: 50%;
  display: inline-block;
  float: right;
  position: absolute;
  right: 134px;
}
.floating-text > h4 {
  padding-top: 455px;
  padding-left: 175px;
}
