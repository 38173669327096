.sizeSabiasque{
    font-family: OpenSans-ExtraBold !important;
    font-size: 1.5rem !important;
}

.bg-sabiasquejovenes {
    background-image: url("assets/images/falabella/jovenes/Falabella-Jovenes-Talentos2.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 110vh;
}


#mi-modal-sabias .profile-card {
    max-width: 500px;
    margin: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    overflow: hidden;
  }
  
  #mi-modal-sabias .profile-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  #mi-modal-sabias .profile-card .card-body {
    padding: 10px;
    background-color: black;
  }

  #mi-modal-sabias .card-footer {
    background-color: black;
  }
  
  #mi-modal-sabias .profile-card .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  #mi-modal-sabias .profile-card .card-text {
    font-size: 18px;
    line-height: 1.5;
    color: #666;
  }

  #mi-modal-sabias .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
    left: 0%;
  }

  @media (max-width: 996px) {
    #mi-modal-sabias .modal-content {
      background-color: transparent !important;
      box-shadow: none !important;
      border: none !important;
      left: 0%;
    }
  }
 
  
  #mi-modal-sabias .modal-dialog {
    margin: 0;
  }